<template>
	<nav class="navbar navbar-expand-lg p-0" v-if="$store.getters['User/getUser']">
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="collapse navbar-collapse" id="navbarSupportedContent">

			<div class="d-flex justify-content-end align-items-center w-100 py-1 px-3">

				<running-jobs class="mx-2"/>

				<team-switcher></team-switcher>
				<router-link :to="{name: 'profile-index'}" tag="div" class="cursor-pointer">
					<agent-card :agent="$store.getters['User/getUser']" class="text-dark"></agent-card>
				</router-link>
			</div>

		</div>
	</nav>
</template>
<style scoped lang="scss">
	a, .nav-link.disabled {
		color: inherit;
	}
</style>
<script>
import AgentCard from "../agents/agent-card";
import TeamSwitcher from "../team/team-switcher";
import RunningJobs from "./RunningJobs";

export default {
		components: {RunningJobs, TeamSwitcher, AgentCard},
		data: () => ({jobs: []}),

	}
</script>
