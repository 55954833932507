<template>
	<div class="d-inline-flex align-items-center justify-content-center px-2 pb-1 rounded-pill" :class="{'flex-column': compact, 'agent-wrapper': !compact}" :title="agent.Username">

		<agent-profile-picture :avatar="agent.Avatar"></agent-profile-picture>

		<div class="text-small" :class="{'d-flex': compact}">
			<div class="ml-2 font-weight-bold" v-text="agentName"></div>
			<div v-if="agent.Role" class="ml-2 text-small" v-text="agent.Role.Name"></div>
		</div>

		<slot></slot>
	</div>
</template>

<script>
	import AgentProfilePicture from './agent-profile-picture'

	export default {
		props: {
			compact: {
				type: Boolean,
				default: false
			},
			agent: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		computed: {
			agentName(){
				if(this.compact){
					return this.agent.Username.split(" ").map(u => `${u.substring(0, 1)}.`).join(" ")
				}else{
					return this.agent.Username
				}
			}
		},
		components: {
			AgentProfilePicture
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	.agent-wrapper {
		background-color: #EFEFEF;

		&:hover {
			background-color: $color-primary;
			color: white;
		}
	}
</style>
