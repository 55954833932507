<template>
	<div class="d-flex main-wrapper">
		<sidebar class="bg-dark text-contrast-dark"></sidebar>
		<div class="w-100 text-contrast-dark pb-3">
			<navbar class="bg-dark"></navbar>
			<div class="container-fluid">
				<transition :name="transitionName">
					<router-view class="p-3 main-container" :key="$route.fullPath"/>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	import Navbar from '@/components/layout/navbar'
	import Sidebar from '@/components/layout/sidebar'
	import Toolbar from '@/components/layout/toolbar'

	export default {
		data() {
			return {
				transitionName: "fade"
			}
		},
		beforeRouteUpdate (to, from, next) {
			console.log('beforeRouteUpdate')
		},
		watch: {
			'$route'(to, from) {
				const toDepth = to.path.split('/').length
				const fromDepth = from.path.split('/').length
				this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
			}
		},
		components: {
			Navbar,
			Sidebar,
			Toolbar
		}
	}
</script>

<style scoped lang="scss">
	.main-wrapper {
		overflow: hidden;

		.main-container {
			max-height: calc(100vh - 64px);
			overflow-y: auto;
			color: black;
			padding-bottom: 300px;
		}
	}
</style>
