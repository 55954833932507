<template>
  <router-link :to="{name: 'jobs-running'}" class="btn btn-sm btn-success px-3 py-1" v-if="jobs.length > 0">
    <div class="d-flex align-items-center">
			<div><strong>{{jobs.length}}</strong> RUNNING JOBS</div>
		</div>
  </router-link>
</template>
<script>
import api from '@/api'
export default {
  name: 'running-jobs',
	data: () => ({jobs: [], interval: null}),
	mounted() {
		this.updateJobs()
		this.interval = setInterval(this.updateJobs, 10000)
	},
	destroyed() {
		clearInterval(this.interval)
	},
	methods: {
		async updateJobs() {
			this.jobs = await api.Jobs.getRunningJobs()
		}
	}
}
</script>
