<template>
	<sidebar-menu :collapsed="false" class="sidebar" :menu="menu" :relative="true"/>
</template>

<script>
import {SidebarMenu} from 'vue-sidebar-menu'

export default {
	components: {
		SidebarMenu
	},
	computed: {
		projects() {
			return this.$store.getters["Projects/list"]
		},
		menu() {

			if (!this.$store.getters['User/getUser']) {
				return []
			}

			let menu = [
				{
					header: true,
					title: 'SLOT MAKER',
					hiddenOnCollapse: true
				},
				{
					href: {
						name: 'dashboard-index'
					},
					title: 'Dashboard',
					icon: 'fa fa-tachometer-alt',
				},
			];

			let projects = []

			if (this.canRead('projects')) {
				projects = this.projects.map((p, index) => {

							//let project_class = `project-${index + 1}`

							let children = []
							if (p.ProjectConfigs !== undefined) {
								children = p.ProjectConfigs.map(pc => {

									let project_actions = []

									if (this.canRead('projects_config')) {
										project_actions.push({
													href: {
														name: 'projects-editor-uuid-cuuid-settings', params: {
															uuid: p.Uuid,
															cuuid: pc.Uuid
														}
													},
													//class: project_class,
													icon: 'fa fa-sliders-h',
													title: 'Settings'
												},
										)

										project_actions.push({
													href: {
														name: 'projects-editor-uuid-cuuid-config-index', params: {
															uuid: p.Uuid,
															cuuid: pc.Uuid
														}
													},
													//class: project_class,
													icon: 'fa fa-cog',
													title: 'Configuration'
												},
										)
									}

									if (this.canRead('simulator')) {
										project_actions.push({
											href: {
												name: 'projects-editor-uuid-cuuid-tests-index', params: {
													uuid: p.Uuid,
													cuuid: pc.Uuid
												}
											},
											//class: project_class,
											icon: 'fa fa-play',
											title: 'Simulator'
										})
									}

									if (this.canRead('spinners')) {
										project_actions.push(...[
											{
												href: {
													name: 'projects-editor-uuid-cuuid-tests-spinner-index',
													params: {
														uuid: p.Uuid,
														cuuid: pc.Uuid
													}
												},
												//class: project_class,
												icon: 'fa fa-vials',
												title: 'Spinner'
											},
											{
												href: {
													name: 'projects-editor-uuid-cuuid-tests-reports-index',
													params: {
														uuid: p.Uuid,
														cuuid: pc.Uuid
													}
												},
												//class: project_class,
												icon: 'fa fa-flag-checkered',
												title: 'Reports'
											},
											{
												href: {
													name: 'projects-editor-uuid-cuuid-tests-combinations-index',
													params: {
														uuid: p.Uuid,
														cuuid: pc.Uuid
													}
												},
												//class: project_class,
												icon: 'fa fa-object-group',
												title: 'Combinations'
											},
											{
												href: {
													name: 'projects-editor-uuid-cuuid-tests-gameplay-index',
													params: {
														uuid: p.Uuid,
														cuuid: pc.Uuid
													}
												},
												//class: project_class,
												icon: 'fa fa-gamepad',
												title: 'Gameplay'
											}
										])
									}

									if (this.canRead('localization')) {
										project_actions.push({
											href: {
												name: 'projects-editor-uuid-cuuid-localizations-index',
												params: {
													uuid: p.Uuid,
													cuuid: pc.Uuid
												}
											},
											title: 'Localization',
											icon: 'fa fa-language',
										})
									}

									let badge_class = 'vsm--badge_default'

									switch (pc.Status) {
										case 'released':
											break;
									}

									return {
										title: pc.Name,
										badge: {
											text: pc.Status,
											class: `vsm--badge_default vsm--badge_status-${pc.Status}`
										},
										//class: project_class,
										icon: 'fa fa-project-diagram',
										child: project_actions
									}
								})

								if (this.canWrite('projects_config')) {
									children.push({
										href: {
											name: 'projects-editor-uuid-config-new', params: {
												uuid: p.Uuid
											}
										},
										//class: project_class,
										title: "New configuration",
										icon: 'fa fa-plus'
									})
								}
							}

							return {
								/*
								href: {
									name: 'projects-editor-uuid-index', params: {
										uuid: p.Uuid
									}
								},
								*/
								hiddenOnCollapse: false,
								title: p.Name,
								icon: {
									text: p.Code
								},
								exactPath: false,
								child: children,
								//class: project_class,
								/*
								child: [
									{
										href: {
											name: 'projects-editor-uuid-index', params: {
												uuid: p.Uuid
											}
										},
										title: "Link"
									}
								]
								 */
							}
						}
				).sort((a, b) => b.Code > a.Code ? 1 : -1)

				if (this.canWrite('projects')) {
					projects.push({
						href: {
							name: 'projects-editor-new'
						},
						title: 'New project',
						icon: 'fa fa-plus-circle',
					})
				}

				menu.push({
					href: {
						name: 'projects-index'
					},
					title: 'Projects',
					icon: 'fa fa-project-diagram',
					child: projects,
					collapsed: false
				})

			}

			if (this.canRead('teams')) {
				menu.push({
					href: {
						name: 'teams-index'
					},
					title: 'Teams',
					icon: 'fa fa-chalkboard-teacher',
				})
			}

			if (this.canRead('users')) {
				menu.push({
					href: {
						name: 'users-index'
					},
					title: 'Users',
					icon: 'fa fa-users'
				})
			}

			if (this.canRead('roles')) {
				menu.push({
					href: {
						name: 'roles-index'
					},
					title: 'Roles',
					icon: 'fa fa-user-tag',
				})
			}

			if (this.canRead('games')) {
				menu.push({
					href: {
						name: 'games-index'
					},
					title: 'Lab Games',
					icon: 'fa fa-gamepad',
				})
			}

			if (this.canRead('localization')) {
				menu.push({
					href: {
						name: 'languages-index'
					},
					title: 'Languages',
					icon: 'fa fa-language',
				})
			}

			menu.push({
						href: {
							name: 'logout'
						},
						title: 'Logout',
						icon: 'fa fa-lock',
					},
			)

			return menu
		}
	}
}
</script>

<style lang="scss" scoped>
.sidebar {
	height: 100vh !important;
}
</style>

<style lang="scss">
.vsm--badge.vsm--badge_default{
	border: 1px solid;

	&.vsm--badge_status- {

		&created {
			background: #b2fbff;
			color: #232323;
		}

		&development {
			background: #ff7400;
			color: #FFF;
		}

		&testing {
			background: #a3ff00;
			color: #3c3c3c;
		}

		&ready {
			background-color: #222;
			color: white;
			animation-name: color;
			animation-duration: 2s;
			animation-iteration-count: infinite;
		}

		&released {
			background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
			color: #6d0e0e;
			border: 1px solid #B38728;
		}

	}
}

@keyframes color {
	0% {
	}

	50% {
		background-color: #4285f4;
	}

	100% {
	}
}

</style>
