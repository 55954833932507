<template>
	<div class="toolbar p-3">
		<div class="row">
			<div class="col-auto">
				<date-range-picker
						ref="picker"
						minDate="2018-12-01" :maxDate="new Date()"
						:timePicker="false"
						:showWeekNumbers="false"
						:showDropdowns="true"
						v-model="dates" ,
						:dateRange="{}"
				></date-range-picker>
			</div>
		</div>
	</div>
</template>

<script>
	import DateRangePicker from 'vue2-daterange-picker'
	//you need to import the CSS manually (in case you want to override it)
	export default {
		data() {
			return {
				dates: {
					startDate: null,
					endDate: null
				}
			}
		},
		components: {
			DateRangePicker
		}
	}
</script>
