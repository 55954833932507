<template>
	<div class="mx-2 py-1 px-2 d-flex justify-content-center" v-if="teams.length > 1">
		<div class="text-right">
			<dropdown :menu-right="true" size="sm" ref="dropdown">
				<btn class="text-white text-small btn-sm px-0 dropdown-toggle d-inline-flex align-items-center">
					<div>Current team <span class="font-weight-bold"
															   v-text="currentTeam.Name"></span></div>
				</btn>
				<template slot="dropdown">
					<li v-for="team in teams" v-if="team.Id !== currentTeam.Id">
						<a role="button" v-text="team.Name" @click="changeCurrentTeam(team)"></a>
					</li>
				</template>
			</dropdown>
		</div>

	</div>
</template>

<script>
	export default {
		methods: {
			async changeCurrentTeam(team) {
				await this.$store.commit("User/currentTeam", team.Id)
				this.$store.dispatch('Projects/load')
			}
		},
		computed: {
			currentTeam() {
				return this.$store.getters["User/currentTeam"]
			},
			teams() {
				return this.$store.getters["User/teams"]
			}
		}
	}
</script>
